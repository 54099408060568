import { useState, useEffect } from 'react';
import { injectHtmlWithInlineJS, sendInteraction } from '../../utils/helpers';
import ProofBubbles from '../ProofBubbles';

const CTAPageReveal = ({
	ctas,
	webinar_id,
	replay,
	booking_id,
	booking,
	timecode,
	hs_list_proof,
	trigger,
	actionText,
	moderator = false
}) => {
	const [activeCtas, setActiveCtas] = useState([]);
	const [page_reveal_url, setPage_reveal_url] = useState('');
	useEffect(() => {
		const activeCtas = ctas.filter((cta) => {
			if (
				cta.widget_type === 'reveal' &&
				cta.show_time < timecode &&
				(cta.hide_time === 0 || cta.hide_time > timecode) &&
				(!replay || cta.show_on_replay)
			) {
				return true;
			}
			return false;
		});

		setActiveCtas(activeCtas);
	}, [timecode]);

	useEffect(() => {
		activeCtas.map((cta) => {
			// Load any scripts
			if (!moderator) {
				if (cta.embed_script) {
					injectHtmlWithInlineJS(cta.id, cta.embed_script, booking);
				}

				// Send interaction
				sendInteraction('cta_viewed', booking_id, webinar_id, {
					value_string: cta.id
				});
			}

			// Trigger the reveal
			setPage_reveal_url(cta.reveal_url);
			trigger(true);
		});

		if (activeCtas.length === 0) {
			trigger(false);
		}
	}, [activeCtas.length]);

	return (
		<>
			{activeCtas.length > 0 && (
				<ProofBubbles
					hs_list_proof={hs_list_proof}
					actionText={actionText}
				/>
			)}
			{activeCtas.length > 0 && (
				<div className="page-reveal">
					<iframe
						src={page_reveal_url}
						title="Reveal Page"
						className="sales-page-iframe"
						frameBorder="0"
						allowFullScreen
					/>
				</div>
			)}
		</>
	);
};

export default CTAPageReveal;
