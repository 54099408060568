import React, { useState } from 'react';
import Calculator from './Calculator';
import './index.css';

function Tm_Calculator() {
	const [initialInvestment, setInitialInvestment] = useState('50000');
	const [formattedInitialInvestment, setFormattedInitialInvestment] =
		useState('$50,000');

	const [currentReturn, setCurrentReturn] = useState('11.5');
	const [potentialReturn, setPotentialReturn] = useState('23.8');
	const [period, setPeriod] = useState('10');

	const handleInitialInvestmentChange = (e) => {
		const value = e.target.value.replace(/[^0-9]/g, '');
		setInitialInvestment(value);
		// Format the value for display as currency
		const formattedValue = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			// Do not show cents
			minimumFractionDigits: 0,
			maximumFractionDigits: 0
		}).format(value);
		setFormattedInitialInvestment(formattedValue);
	};

	const handleCurrentReturnChange = (e) => {
		const value = e.target.value.replace(/[^0-9.]/g, '');
		setCurrentReturn(value);
	};

	const handlePotentialReturnChange = (e) => {
		const value = e.target.value.replace(/[^0-9.]/g, '');
		setPotentialReturn(value);
	};

	const handlePeriodChange = (e) => {
		setPeriod(e.target.value);
	};

	const resetDefaults = () => {
		setInitialInvestment('50000');
		setCurrentReturn('11.5');
		setPotentialReturn('23.8');
		setPeriod('10');
	};

	const formatCurrency = (value) => {
		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0
		}).format(value);
	};

	const formatPercentage = (value) => {
		return `${Number(value).toFixed(2)}%`;
	};

	return (
		<div className="CalcAppApp container">
			<h1>Investment Calculator</h1>
			<div className="reset-link" onClick={resetDefaults}>
				Reset Defaults
			</div>
			<div className="input-container">
				<label>
					Initial Investment:
					<input
						type="text"
						value={formattedInitialInvestment}
						onChange={handleInitialInvestmentChange}
						placeholder="$"
					/>
				</label>
				<label>
					Period (Years):
					<select value={period} onChange={handlePeriodChange}>
						<option value="10">10 Years</option>
						<option value="20">20 Years</option>
						<option value="30">30 Years</option>
					</select>
				</label>
				<label>
					Current Annual Return (%):
					<input
						type="text"
						value={currentReturn}
						onChange={handleCurrentReturnChange}
						placeholder="%"
					/>
				</label>
				<label>
					Potential Return (%):
					<input
						type="text"
						value={potentialReturn}
						onChange={handlePotentialReturnChange}
						placeholder="%"
					/>
				</label>
			</div>
			<Calculator
				initialInvestment={Number(initialInvestment)}
				currentReturn={Number(currentReturn) / 100}
				potentialReturn={Number(potentialReturn) / 100}
				period={Number(period)}
			/>
		</div>
	);
}

export default Tm_Calculator;
