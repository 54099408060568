import { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player/vimeo';
import CountdownTimer from '../CountdownTimer';

import './index.css';

const WebinarVideo = ({
	minimized,
	status, // live, finished, early
	vimeo_id,
	replay,
	videoTimer,
	start_time
}) => {
	const [hasStarted, setHasStarted] = useState(false);
	const playerRef = useRef(null);

	const startWebinar = () => {
		// when the button is pushed, start the webinar
		if (!playerRef || !playerRef.current) return;

		setHasStarted(true);
		const secondsPast = getLivePosition();
		try {
			if (
				secondsPast > 0 &&
				secondsPast < playerRef.current.getDuration()
			) {
				playerRef.current.seekTo(secondsPast);
			}
		} catch (e) {
			console.error('Error seeking:', e);
		}
	};

	const handleSeek = () => {
		// if they somehow work out how to seek
		if (replay) return;
		const secondsPast = getLivePosition();

		if (
			playerRef.current.getCurrentTime() > secondsPast + 5 ||
			playerRef.current.getCurrentTime() < secondsPast - 5
		) {
			if (
				secondsPast > 0 &&
				secondsPast < playerRef.current.getDuration()
			) {
				playerRef.current.seekTo(secondsPast);
			}
		}
	};

	const handlePause = () => {
		// Unpause if somehow managed to be paused
		if (replay) return;
		playerRef.current.getInternalPlayer().play();
	};

	const getLivePosition = () => {
		// calculate how many seconds have past since the start_time
		const now = new Date();
		const startTime = new Date(start_time);
		const secondsPast = (now - startTime) / 1000;
		return secondsPast;
	};

	return (
		<>
			{(status === 'live' || replay) && (
				<div
					className={`video-player-wrapper ${
						minimized ? 'minimized' : ''
					}`}
				>
					<ReactPlayer
						url={`https://vimeo.com/${vimeo_id}`}
						ref={playerRef}
						className="video-player"
						playing={hasStarted}
						controls={replay ? true : false}
						onSeek={handleSeek}
						width="100%"
						playsinline={true}
						height="100%"
						onProgress={videoTimer}
						onPause={handlePause}
						onPlay={startWebinar}
					/>
					{!hasStarted && !minimized && (
						<button
							className="webinar-start-button"
							onClick={startWebinar}
						>
							<i className="fas fa-play"></i>{' '}
							{replay ? 'Watch the Replay' : 'Join the Webinar'}
						</button>
					)}
					{!minimized && replay && (
						<div className="replay-indicator">REPLAY</div>
					)}
					{!minimized && !replay && (
						<div className="live-indicator">LIVE</div>
					)}
				</div>
			)}
			{status === 'finished' && !replay && (
				<div className="webinar-finished">
					<h1>The webinar has finished</h1>
				</div>
			)}

			{status === 'early' && (
				<div className="webinar-finished">
					<h1>The webinar will begin in:</h1>
					<CountdownTimer
						startTime={new Date(start_time)}
						duration={0}
						prefix=""
					/>
				</div>
			)}
		</>
	);
};

export default WebinarVideo;
