import React, { useState, useEffect, useCallback } from 'react';
import './index.css';

const CountdownTimer = ({ startTime, duration, prefix }) => {
	const calculateTimeLeft = useCallback(() => {
		const now = new Date();
		const endTime = new Date(startTime.getTime() + duration * 60000);
		const difference = endTime - now;

		// Calculate time left in hours, minutes, and seconds
		const timeLeft = {
			hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
			minutes: Math.floor((difference / 1000 / 60) % 60),
			seconds: Math.floor((difference / 1000) % 60)
		};

		return timeLeft;
	}, [startTime, duration]);

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	// Recalculate time left every second
	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);
		return () => clearInterval(timer);
	}, [calculateTimeLeft]);

	return (
		<div className="countdownTimer">
			{prefix} {timeLeft.hours ? <span>{timeLeft.hours}h </span> : ''}
			<span>{timeLeft.minutes}m </span>
			<span>{timeLeft.seconds}s</span>
		</div>
	);
};

export default CountdownTimer;
