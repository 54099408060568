import React from 'react';
import './index.css';

const MobileNotification = () => {
	return (
		<div className="mobileNotification">
			This training is best experienced on a tablet, desktop, or laptop
			computer. You may not be able to fully participate in the training
			on a mobile device.
		</div>
	);
};

export default MobileNotification;
