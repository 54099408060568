import React, { useEffect, useState } from 'react';
import './index.css';

const ProofBubbles = ({ hs_list_proof, actionText, interval = 20 }) => {
	const [data, setData] = useState([]);
	const [showBubble, setShowBubble] = useState(false);
	const [randomData, setRandomData] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(
					`https://knowledgesource.com.au/js/proof.php?l=${hs_list_proof}`
				);
				const data = await response.json();
				setData(data);
			} catch (e) {
				console.log('Error loading proof bubbles');
			}
		};

		fetchData();
	}, [hs_list_proof]);

	useEffect(() => {
		if (data && data.length > 0) {
			const randomData = data[Math.floor(Math.random() * data.length)];
			setRandomData(randomData);
			setShowBubble(true);
			const timer = setInterval(() => {
				setShowBubble(false);
				setTimeout(() => {
					const randomData =
						data[Math.floor(Math.random() * data.length)];
					setRandomData(randomData);
					setShowBubble(true);
				}, (interval * 1000) / 2);
			}, interval * 1000);
			return () => clearInterval(timer);
		}
	}, [data, actionText, interval]);

	return (
		showBubble &&
		randomData && (
			<div className="bubble">
				<div className="image">
					<img
						className="proofImage"
						src="/img/staticmap.png"
						alt="proof"
					/>
				</div>
				<div className="content">
					<div className="who">
						{randomData.name} from {randomData.location}
					</div>
					<div className="what">
						{actionText || 'Just took action'}
					</div>
					<div className="when">
						<time>
							<span className="when1">Secure Yours Now!</span>
						</time>
					</div>
				</div>
			</div>
		)
	);
};

export default ProofBubbles;
