export const loadOnceHubPlugin = function (elementId) {
	var StaticURL = 'https://cdn.oncehub.com';

	loadSOMBD();

	function loadSOMBD() {
		var script = document.createElement('script');
		script.src = StaticURL + '/mergedjs/ScheduleOnceEmbed.js';
		script.id = 'soembed' + elementId;
		script.onload = initBookingControls;

		if (!document.getElementById('soembed')) {
			document.getElementsByTagName('head')[0].appendChild(script);
		}
	}

	function initBookingControls() {
		var bctrl = document.getElementById(elementId);
		if (bctrl) {
			if (bctrl.getAttribute('data-so-done') != 'true') {
				var qprm = bctrl.getAttribute('data-so-qry-prm');
				qprm = qprm ? (qprm[0] === '&' ? qprm : '&' + qprm) : '';
				function getPrm(b) {
					var p = window.soe.getAttr(bctrl, 'data-psz');
					if (
						!p ||
						p.length != 2 ||
						['00', '01', '11', '10'].indexOf(p) == -1
					)
						p = '00';
					return [(p[0] == '1').toString(), (p[1] == '1').toString()];
				}
				function getHght(h, d) {
					if (h) h = h.trim();
					return !h || (h.indexOf('px') == -1 && isNaN(h))
						? [d, 0]
						: [h.replace('px', ''), 1];
				}
				//Embed control
				if (bctrl.id.toUpperCase().indexOf('SOIDIV_') != -1) {
					var page = window.soe.getAttr(bctrl, 'data-so-page');
					var brdrWdth = '1px',
						brdrClr = '#d8d8d8';
					var brdr = window.soe
						.getAttrPrms(
							bctrl,
							'data-style',
							'border',
							brdrWdth + ' solid ' + brdrClr
						)
						.split(' solid #');
					try {
						brdrWdth = brdr[0];
						brdrClr = brdr[1].trim();
					} catch (e) {
						brdrWdth = '1px';
						brdrClr = '#d8d8d8';
					}
					var hght = getHght(
						window.soe.getAttr(bctrl, 'data-height'),
						'550'
					);
					var param = getPrm(bctrl);
					var isPrmUrl = param[0];
					var isSoSkp = param[1];
					var minW = window.soe.getAttrPrms(
						bctrl,
						'data-style',
						'min-width',
						'290px'
					);
					var maxW = window.soe.getAttrPrms(
						bctrl,
						'data-style',
						'max-width',
						'900px'
					);
					window.soe.setAttrPrms(bctrl, 'data-style', {
						'min-width': minW,
						'max-width': maxW
					});
					window.soe.AddEventListners(
						window.soe.CfURL +
							'/' +
							page +
							'?brdr=' +
							brdrWdth +
							brdrClr +
							'&dt=&em=1' +
							qprm,
						page,
						hght[0] + 'px',
						'100% !important',
						isPrmUrl,
						isSoSkp,
						!!hght[1]
					);
				} else if (bctrl.id.toUpperCase().indexOf('SOIBTN_') != -1) {
					//Button control
					var page = window.soe.getAttr(bctrl, 'data-so-page');
					var txt = window.soe.getAttr(
						bctrl,
						'value',
						'Schedule an Appointment'
					);
					var bg = window.soe.getStyl(bctrl, 'background', '#006ADF');
					var clr = window.soe.getStyl(bctrl, 'color', '#ffffff');
					var param = getPrm(bctrl);
					var isPrmUrl = param[0];
					var isSoSkp = param[1];
					var hght = getHght(
						window.soe.getAttr(bctrl, 'data-height'),
						'580'
					);
					window.soe.InitButton(
						window.soe.CfURL + '/' + page,
						page,
						txt,
						bg,
						clr,
						isPrmUrl,
						!!hght[1],
						hght[0] + 'px',
						qprm
					);
					window.soe.AddEventListners(
						'',
						'',
						'',
						'',
						isPrmUrl,
						isSoSkp,
						!!hght[1]
					);
					bctrl.setAttribute(
						'onclick',
						'return window.soe.toggleLightBox("' + page + '")'
					);
				} else if (bctrl.id.toUpperCase().indexOf('SOIWGT_') != -1) {
					//Widget control
					var page = window.soe.getAttr(bctrl, 'data-so-page');
					var wstl = 'data-wgt-style';
					var wttl = window.soe.getAttrPrms(
						bctrl,
						wstl,
						'ttl',
						'Free 1-on-1 Consultation'
					);
					var wbg = window.soe.getAttrPrms(
						bctrl,
						wstl,
						'bg',
						'333333'
					);
					var algn = window.soe.getAttrPrms(
						bctrl,
						wstl,
						'align',
						'right'
					);
					var wclr = window.soe.getAttrPrms(
						bctrl,
						wstl,
						'clr',
						'ffffff'
					);
					var hght = getHght(
						window.soe.getAttr(bctrl, 'data-height'),
						'580'
					);
					var targetUrl = window.soe.getAttr(bctrl, 'data-target');
					var tmp = window.soe
						.getAttr(bctrl, 'data-tmp', '177.178')
						.split('.');
					var ctid = tmp[0];
					var utid = tmp.length > 1 ? tmp[1] : '178';
					var pos = window.soe.getAttr(bctrl, 'data-pos', '132');
					var invldpos = false;
					if (pos.length != 3) invldpos = true;
					else {
						for (var x = 0; x < pos.length; x++)
							if (['0', '1', '2', '3'].indexOf(pos[x]) == -1)
								invldpos = true;
						if (
							(pos[0] != '0' &&
								(pos[0] == pos[1] || pos[0] == pos[2])) ||
							(pos[1] != '0' && pos[1] == pos[2])
						)
							invldpos = true;
					}
					if (invldpos) pos = '132';
					var bstl = 'data-btn-style';
					var bttl = window.soe.getAttrPrms(
						bctrl,
						bstl,
						'ttl',
						'Schedule Online'
					);
					var btxt = window.soe.getAttrPrms(
						bctrl,
						bstl,
						'txt',
						'Free 1-on-1 Consultation'
					);
					var bbg = window.soe.getAttrPrms(
						bctrl,
						bstl,
						'bg',
						'006DAF'
					);
					var bclr = window.soe.getAttrPrms(
						bctrl,
						bstl,
						'clr',
						'ffffff'
					);
					var pstl = 'data-phn-style';
					var pttl = window.soe.getAttrPrms(
						bctrl,
						pstl,
						'ttl',
						'Call Us'
					);
					var ptxt1 = window.soe.getAttrPrms(bctrl, pstl, 'phn1');
					var ptxt2 = window.soe.getAttrPrms(bctrl, pstl, 'phn2', '');
					var estl = 'data-eml-style';
					var ettl = window.soe.getAttrPrms(
						bctrl,
						estl,
						'ttl',
						'Email Us'
					);
					var etxt = window.soe.getAttrPrms(
						bctrl,
						estl,
						'txt',
						'Send'
					);
					var ebg = window.soe.getAttrPrms(
						bctrl,
						estl,
						'bg',
						'006DAF'
					);
					var eclr = window.soe.getAttrPrms(
						bctrl,
						estl,
						'clr',
						'ffffff'
					);
					var soeml = window.soe.getAttr(bctrl, 'data-email');
					var eto = window.soeml
						? soeml.substring(
								soeml.indexOf('(') + 1,
								soeml.indexOf(')')
						  )
						: '';
					var elbl = window.soeml
						? soeml.substr(0, soeml.indexOf(' ('))
						: '';
					var emsg = window.soe.getAttr(bctrl, 'data-message');
					window.soe.InitWidget(
						window.soe.CfURL + '/' + page,
						page,
						btxt,
						bbg,
						bclr,
						pos[0] + ',' + pos[1] + ',' + pos[2],
						pttl,
						ptxt1 + '_so_phone_sep_' + ptxt2,
						ettl,
						etxt,
						eclr,
						ebg,
						eto,
						elbl,
						emsg,
						ctid,
						utid,
						bttl,
						wttl,
						wclr,
						wbg,
						algn,
						'',
						!!hght[1],
						hght[0],
						targetUrl,
						qprm
					);
					window.soe.AddEventListners();
				}
				bctrl.setAttribute('data-so-done', 'true');
			}
		}
	}
};
