import { useEffect, useState } from 'react';
import { supabase } from '../../utils/supabase';
import './index.css';

const LiveSessions = () => {
	const [sessions, setSessions] = useState([]);

	useEffect(() => {
		const getData = async () => {
			const { data, error } = await supabase
				.from('live_sessions')
				.select('*')
				.order('project', { ascending: true });
			if (error) {
				console.log(error);
			} else {
				const sessions = data.reduce((acc, session) => {
					const index = acc.findIndex(
						(project) => project.project === session.project
					);
					if (index === -1) {
						acc.push({
							project: session.project,
							sessions: [
								{
									webinar: session.webinar,
									start_time: session.start_time,
									jump_in_url: session.jump_in_url,
									booking_count: session.booking_count
								}
							]
						});
					} else {
						acc[index].sessions.push({
							webinar: session.webinar,
							start_time: session.start_time,
							jump_in_url: session.jump_in_url,
							booking_count: session.booking_count
						});
					}
					return acc;
				}, []);

				setSessions(sessions);
			}
		};

		getData();
	}, []);

	return (
		<div>
			<div className="container" id="sessions-container">
				{sessions.map((project) => (
					<div className="project" key={project.project}>
						<h2>{project.project}</h2>

						{project.sessions.map((session) => (
							<div
								className="session"
								key={session.webinar + session.start_time}
							>
								<div>
									<strong>{session.webinar}: </strong>
									{session.booking_count}
									<br />
									<small>
										{new Date(
											session.start_time
										).toLocaleTimeString()}
									</small>
								</div>
								<a
									href={session.jump_in_url}
									target="_new"
									className="join-button"
								>
									Join
								</a>
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};
export default LiveSessions;
