import { useState, useEffect } from 'react';
import { addUrlParams } from '../../utils/helpers';
import CountdownTimer from '../CountdownTimer';
import ProofBubbles from '../../components/ProofBubbles';
import { sendInteraction, injectHtmlWithInlineJS } from '../../utils/helpers';
import { loadOnceHubPlugin } from '../../utils/oncehub';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import './index.css';

const CTAWidgets = ({
	ctas,
	webinar_id,
	replay,
	booking_id,
	booking,
	timecode,
	hs_list_proof,
	actionText,
	moderator = false
}) => {
	const [activeCtas, setActiveCtas] = useState([]);

	useEffect(() => {
		const activeCtas = ctas.filter((cta) => {
			if (
				cta.widget_type === 'widget' &&
				cta.show_time < timecode &&
				(cta.hide_time === 0 || cta.hide_time > timecode) &&
				(!replay || cta.show_on_replay)
			) {
				return true;
			}
			return false;
		});

		setActiveCtas(activeCtas);
	}, [timecode]);

	useEffect(() => {
		if (moderator) return;
		activeCtas.map((cta) => {
			// Load any scripts
			if (cta.embed_script) {
				injectHtmlWithInlineJS(cta.id, cta.embed_script, booking);
			}

			// Send interaction
			sendInteraction('cta_viewed', booking_id, webinar_id, {
				value_string: cta.id
			});
		});
	}, [activeCtas.length]);

	const ctaClickHandler = (cta, e) => {
		if (cta.is_calendly_popup && window.Calendly) {
			e.preventDefault();
			window.Calendly.initPopupWidget({ url: cta.widget_btn_url });

			// Send interaction
			sendInteraction('cta_click', booking_id, webinar_id, {
				value_string: cta.id
			});

			return false;
		}

		if (moderator) return;

		// Send interaction
		sendInteraction('cta_click', booking_id, webinar_id, {
			value_string: cta.id
		});
	};

	const formatPhoneNumberForSO = (internationalNumber) => {
		try {
			const phoneNumber = parsePhoneNumberFromString(internationalNumber);

			if (phoneNumber) {
				// Get the local number with area code
				return phoneNumber.formatNational().replace(/\D/g, '');
			} else {
				return '';
			}
		} catch (e) {
			return internationalNumber || '';
		}
	};

	return (
		<>
			{activeCtas.length > 0 && (
				<ProofBubbles
					hs_list_proof={hs_list_proof}
					actionText={actionText}
				/>
			)}
			{activeCtas.length > 0 && (
				<div className="cta-container">
					{activeCtas.map((cta, idx) => {
						return (
							<div
								key={idx}
								className={`cta-widget`}
								id={`cta-${cta.id}`}
							>
								{cta.widget_closeable && (
									<button
										className="closeButton"
										onClick={() => {
											document.querySelector(
												`#cta-${cta.id}`
											).style.display = 'none';
										}}
									>
										<i className="fas fa-times"></i>
									</button>
								)}
								{cta.widget_title && (
									<h2>{cta.widget_title}</h2>
								)}
								{cta.widget_image && (
									<img src={cta.widget_image} alt="" />
								)}
								{cta.widget_body && (
									<div
										dangerouslySetInnerHTML={{
											__html: cta.widget_body
										}}
									/>
								)}
								{cta.widget_btn_text &&
									!cta.is_oncehub_popup && (
										<a
											className="btn cta-button"
											onClick={(e) =>
												ctaClickHandler(cta, e)
											}
											href={addUrlParams(
												cta.widget_btn_url,
												{
													bid: booking_id,
													cta: cta.id
												}
											)}
											target="_new"
											style={{
												backgroundColor:
													cta.button_background_color,
												color: cta.button_text_color
											}}
										>
											{cta.widget_btn_text}
										</a>
									)}
								{cta.widget_btn_text &&
									cta.is_oncehub_popup && (
										<>
											<button
												id={
													'SOIBTN_' +
													cta.widget_btn_url
												}
												className="btn cta-button"
												data-so-qry-prm={
													'name=' +
													booking.first_name +
													(booking.last_name
														? ' ' +
														  booking.last_name
														: '') +
													'&email=' +
													booking.email +
													'&mobile=' +
													formatPhoneNumberForSO(
														booking.phone
													)
												}
												ata-height="580"
												data-psz="10"
												data-so-page={
													cta.widget_btn_url
												}
												data-delay="1"
											>
												{cta.widget_btn_text}
											</button>

											{loadOnceHubPlugin(
												'SOIBTN_' + cta.widget_btn_url
											)}
										</>
									)}

								{cta.widget_disclaimer && (
									<p>
										<small>{cta.widget_disclaimer}</small>
									</p>
								)}
							</div>
						);
					})}
				</div>
			)}
		</>
	);
};
export default CTAWidgets;
