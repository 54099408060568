import './index.css';

function ErrorPage() {
	return (
		<div className="ErrorPage">
			<h1>Oops!</h1>
			<p>
				It seems like something went wrong. But don't worry, our team of
				highly trained monkeys has been dispatched to deal with this
				situation.
			</p>
			<img src="/img/monkey.png" alt="Trained Monkey" />
		</div>
	);
}

export default ErrorPage;
