import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import './Calculator.css';

// Register the necessary components for Chart.js
ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

function Calculator({
	initialInvestment,
	currentReturn,
	potentialReturn,
	period
}) {
	const years = Array.from({ length: period }, (_, i) => i + 1);
	const [randomCurrentReturns, setRandomCurrentReturns] = useState([]);
	const [randomPotentialReturns, setRandomPotentialReturns] = useState([]);

	useEffect(() => {
		const generateRandomReturns = (baseReturn) => {
			let validReturns = [];

			function getPotentialReturns() {
				let returns = Array.from({ length: period }, () => {
					return randomNormalDistribution(baseReturn, 0.18);
				});

				const averageReturn =
					returns.reduce((acc, val) => acc + val, 0) / period;
				const adjustmentFactor = baseReturn / averageReturn;

				return returns.map((r) => r * adjustmentFactor);
			}

			while (validReturns.length === 0) {
				const returns = getPotentialReturns();

				// Calculate what the final value should be if the baseReturn was annualised for period
				const finalValue =
					initialInvestment * (1 + baseReturn) ** period;

				// Calculate the final value using the random returns
				const actualFinalValue = returns.reduce(
					(acc, val) => acc * (1 + val),
					initialInvestment
				);

				const diffPercentage =
					Math.abs(finalValue - actualFinalValue) / finalValue;

				//if more that -10% or less than 10% of the final value, return the returns
				if (diffPercentage < 0.1 && diffPercentage > -0.1) {
					validReturns = returns;
				}
			}

			return validReturns;
		};

		setRandomCurrentReturns(generateRandomReturns(currentReturn));
		setRandomPotentialReturns(generateRandomReturns(potentialReturn));
	}, [currentReturn, potentialReturn, period]);

	const calculateProjections = (initial, returns) => {
		let value = initial;
		return returns.map((rate, index) => {
			value *= 1 + rate;
			return {
				year: index + 1,
				value
			};
		});
	};

	const currentReturnProjections = randomCurrentReturns.length
		? calculateProjections(initialInvestment, randomCurrentReturns)
		: [];
	const potentialReturnProjections = randomPotentialReturns.length
		? calculateProjections(initialInvestment, randomPotentialReturns)
		: [];

	const randomNormalDistribution = (mean = 0, standardDeviation = 1) => {
		let u1 = 0,
			u2 = 0;
		while (u1 === 0) u1 = Math.random(); // Convert [0,1) to (0,1)
		while (u2 === 0) u2 = Math.random();
		const z0 =
			Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2);
		return z0 * standardDeviation + mean;
	};

	const data = {
		labels: years,
		datasets: [
			{
				label: 'With Current Return',
				data: currentReturnProjections.map((p) => p.value),
				borderColor: 'rgba(75, 192, 192, 1)',
				borderWidth: 3,
				pointRadius: 2,
				fill: false
			},
			{
				label: 'With Potential Return',
				data: potentialReturnProjections.map((p) => p.value),
				borderColor: 'rgba(255, 99, 132, 1)',
				borderWidth: 3,
				pointRadius: 2,
				fill: false
			}
		]
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: 'top',
				align: 'start',
				labels: {
					font: {
						size: 14
					},
					padding: 10
				}
			},
			tooltip: {
				enabled: true,
				mode: 'index',
				intersect: false,
				callbacks: {
					label: function (context) {
						let label = context.dataset.label || '';
						if (label) {
							label += ': ';
						}
						label += new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: 0,
							maximumFractionDigits: 0
						}).format(context.raw);
						return label;
					}
				}
			}
		},
		scales: {
			x: {
				title: {
					display: true,
					text: 'Years',
					font: {
						size: 14
					}
				},
				ticks: {
					font: {
						size: 12
					}
				}
			},
			y: {
				ticks: {
					font: {
						size: 12
					},
					callback: function (value) {
						return new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: 0,
							maximumFractionDigits: 0
						}).format(value);
					}
				}
			}
		}
	};

	const formatCurrency = (value) => {
		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0
		}).format(value);
	};

	if (
		currentReturnProjections.length === 0 ||
		potentialReturnProjections.length === 0
	) {
		return null;
	}

	return (
		<div className="calculator">
			<div className="chart-container">
				<Line data={data} options={options} />
			</div>
			<table>
				<thead>
					<tr>
						<th>Year</th>
						<th>With Current Return</th>
						<th>With Potential Return</th>
					</tr>
				</thead>
				<tbody>
					{years.map((year, index) => (
						<tr
							key={year}
							className={
								year === 10 || year === 20 || year === 30
									? 'highlight-row'
									: ''
							}
						>
							<td>{year}</td>
							<td>
								{formatCurrency(
									currentReturnProjections[index]?.value
								)}
							</td>
							<td>
								{formatCurrency(
									potentialReturnProjections[index]?.value
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default Calculator;
