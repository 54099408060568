import React, { useState, useEffect } from 'react';

const WebinarAttendeeCount = () => {
	const [attendees, setAttendees] = useState(
		() => Math.floor(Math.random() * 281) + 120
	);

	const styles = {
		container: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			padding: '10px 0',
			border: '1px solid #ccc',
			backgroundColor: '#f9f9f9'
		},
		attendeeText: {
			fontSize: '16px',
			color: '#777'
		}
	};

	const mobileStyles = {
		container: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			padding: '0px 0'
		},
		attendeeText: {
			fontSize: '12px'
		}
	};

	// Use window.innerWidth to determine screen width
	const isMobile = window.innerWidth <= 768;

	// Use either styles or mobileStyles based on screen width
	const appliedStyles = isMobile ? mobileStyles : styles;

	useEffect(() => {
		const updateAttendees = () => {
			setAttendees((prev) => {
				// your logic here
			});
		};

		// your logic here
	}, []);

	return (
		<div style={appliedStyles.container}>
			<p style={appliedStyles.attendeeText}>
				Currently {attendees} people on the webinar
			</p>
		</div>
	);
};

export default WebinarAttendeeCount;
