import React, { useState, useRef, useEffect } from 'react';
import './index.css';

const Webcam = () => {
	const [isCameraOn, setIsCameraOn] = useState(false);
	const webcamRef = useRef(null);

	useEffect(() => {
		if (isCameraOn) {
			navigator.mediaDevices
				.getUserMedia({ video: true })
				.then((stream) => {
					webcamRef.current.srcObject = stream;
					// Add an event listener for 'loadedmetadata' to play the video
					const playVideo = () => webcamRef.current.play();
					webcamRef.current.addEventListener(
						'loadedmetadata',
						playVideo
					);
					// Clean up: Remove event listener when component unmounts or video stops
					return () => {
						webcamRef.current.removeEventListener(
							'loadedmetadata',
							playVideo
						);
					};
				})
				.catch((err) => {
					// No need to error if denied access to webcam
					setIsCameraOn(false);
				});
		} else {
			if (webcamRef.current && webcamRef.current.srcObject) {
				let tracks = webcamRef.current.srcObject.getTracks();
				tracks.forEach((track) => track.stop());
				webcamRef.current.srcObject = null;
			}
		}
	}, [isCameraOn]);

	useEffect(() => {
		// Code here will run only once after the initial render
		if (window.innerWidth > 768 && !isCameraOn) {
			setIsCameraOn(true);
		}
	}, []); // Empty dependency array means this effect runs only once

	return (
		<div className="webcam-container">
			<button
				onClick={() => setIsCameraOn((prev) => !prev)}
				className="toggle-button"
			>
				{isCameraOn ? (
					<i className="fa fa-video-slash"></i>
				) : (
					<i className="fa fa-video"></i>
				)}
			</button>
			{isCameraOn && (
				<video ref={webcamRef} className="webcam-preview" autoPlay />
			)}
		</div>
	);
};

export default Webcam;
