import { sendInteraction, injectHtmlWithInlineJS } from '../../utils/helpers';
import useWebinarStore from '../../utils/store';

const CTAMarkers = ({ ctas, booking, timecode, moderator = false, replay }) => {
	const activatedCtas = useWebinarStore((state) => state.activatedCtas);

	for (let i = 0; i < ctas.length; i++) {
		if (moderator) return;

		if (
			!activatedCtas.includes(ctas[i].id) &&
			ctas[i].widget_type === 'marker' &&
			ctas[i].show_time < timecode &&
			(ctas[i].hide_time === 0 || ctas[i].hide_time > timecode) &&
			(!replay || ctas[i].show_on_replay)
		) {
			useWebinarStore.setState((state) => ({
				activatedCtas: [...state.activatedCtas, ctas[i].id]
			}));

			// Load any scripts
			if (ctas[i].embed_script) {
				injectHtmlWithInlineJS(
					ctas[i].id,
					ctas[i].embed_script,
					booking
				);
			}

			// Send interaction
			sendInteraction(
				'cta_viewed',
				booking.booking_id,
				booking.webinar_id,
				{
					value_string: ctas[i].id
				}
			);

			console.log('CTA Marker Activated:', ctas[i].id);
		}
	}

	return null;
};

export default CTAMarkers;
