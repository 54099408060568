import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

import {
	MainContainer,
	ChatContainer,
	MessageList,
	Message,
	MessageInput,
	MessageGroup
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './index.css';

const Filter = require('bad-words');
const filter = new Filter();

function ChatBox({
	replay = false,
	booking_id,
	session_time,
	webinar_id,
	from_name,
	timecode,
	reuse = [],
	moderator = false,
	askMaax = false,
	ctaMessages = []
}) {
	const [messages, setMessages] = useState([]);
	const [socket, setSocket] = useState(null);

	const getLocalReusedMessages = () => {
		if (!reuse || reuse.length === 0) {
			return [];
		}
		const skey = `webinar_${webinar_id}_session_${new Date(
			session_time
		).toISOString()}`;

		//check if localStorage.getItem(skey) is null
		if (!localStorage.getItem(skey) || localStorage.getItem(skey) == '[]') {
			const cleanReuse = reuse.filter(
				(msg) => !filter.isProfane(msg.message)
			);
			// if null, set localStorage after removing any nasty ones
			localStorage.setItem(skey, JSON.stringify(cleanReuse));
		}

		return JSON.parse(localStorage.getItem(skey));
	};

	useEffect(() => {
		// will run every second, is there a reuse message with same timecode int?
		const localMessages = getLocalReusedMessages();
		const reusedMessages = localMessages
			.filter((msg) => msg.timecode === timecode && msg.timecode !== 0)
			.map((msg) => ({
				text: msg.message,
				sender: msg.from_name,
				sent: msg.booking_id === booking_id
			}));
		const ctaMessagesToInsert = ctaMessages
			.filter((msg) => msg.show_time === timecode)
			.map((msg) => ({
				text: msg.chat_message,
				sender: 'Moderator',
				sent: false
			}));
		// // add the messages to the chat
		return setMessages((prevMessages) => [
			...prevMessages,
			...reusedMessages,
			...ctaMessagesToInsert
		]);
	}, [timecode]);

	useEffect(() => {
		const newSocket = io(process.env.REACT_APP_SOCKET_IO_SERVER_URL);
		setSocket(newSocket);

		// Join a room
		newSocket.emit('joinRoom', {
			webinar_id,
			session_time
		});

		newSocket.emit('fetchMessages', {
			webinar_id,
			session_time
		});

		newSocket.on('initialMessages', (initialMessages) => {
			if (!initialMessages) {
				return;
			}

			// calculate seconds since the session_time
			const secondsSinceSession = Math.floor(
				(new Date() - new Date(session_time)) / 1000
			);

			const messages = initialMessages.map((msg) => ({
				text: msg.message,
				sender: msg.from_name,
				sent: msg.booking_id === booking_id
			}));

			const localMessages = getLocalReusedMessages();

			const reusedMessages = localMessages
				.filter((msg) => msg.timecode < secondsSinceSession)
				.map((msg) => ({
					text: msg.message,
					sender: msg.from_name,
					sent: msg.booking_id === booking_id
				}));

			const ctaMessagesToInsert = ctaMessages
				.filter((msg) => msg.show_time < secondsSinceSession)
				.map((msg) => ({
					text: msg.chat_message,
					sender: 'Moderator',
					sent: false
				}));

			setMessages([
				...messages,
				...reusedMessages,
				...ctaMessagesToInsert
			]);
		});

		newSocket.on('message', (message) => {
			setMessages((prevMessages) => [
				...prevMessages,
				{
					text: message.message,
					sender: message.from_name,
					sent: message.booking_id === booking_id
				}
			]);
		});

		return () => newSocket.close();
	}, [setSocket]);

	const handleSendMessage = (message) => {
		if (message) {
			const newMessage = {
				webinar_id,
				session_time,
				booking_id,
				from_name,
				message,
				timecode: timecode || 0,
				type: moderator ? 'moderator' : 'attendee',
				askMaax
			};

			socket.emit('message', newMessage);
		}
	};

	return (
		<MainContainer>
			<ChatContainer>
				<MessageList>
					{messages.map((msg, idx) => (
						<MessageGroup
							key={idx}
							header={msg.sender}
							direction={msg.sent ? 'outgoing' : 'incoming'}
						>
							<MessageGroup.Header
								sender={msg.sent ? '' : msg.sender}
							>
								{msg.sent ? '' : msg.sender}
							</MessageGroup.Header>

							<MessageGroup.Messages>
								<Message
									model={{
										sender: msg.sender || '',
										message: msg.text
									}}
								/>
							</MessageGroup.Messages>
						</MessageGroup>
					))}
				</MessageList>

				<MessageInput
					placeholder={
						replay ? 'Chat Disabled on Replay' : 'Type a message...'
					}
					onSend={handleSendMessage}
					disabled={replay}
					attachButton={false}
				/>
			</ChatContainer>
		</MainContainer>
	);
}

export default ChatBox;
