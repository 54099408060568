import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../utils/supabase';
import WebinarVideo from '../../components/WebinarVideo';
import CTAWidgets from '../../components/CTAWidgets';
import CTAPageReveal from '../../components/CTAPageReveal';
import ChatBox from '../../components/ChatBox';
import Webcam from '../../components/Webcam';
import WebinarAttendeeCount from '../../components/WebinarAttendeeCount';
import MobileNotification from '../../components/MobileNotification';
import './index.css';

const JumpIn = ({ setHasError }) => {
	const [webinarData, setWebinarData] = useState(null);
	const [minimized, setMinimized] = useState(false);
	const [videoPosition, setVideoPosition] = useState(0);
	const [status, setStatus] = useState(null);

	const { wid, session_time } = useParams();

	useEffect(() => {
		// fetch the webinar data
		const fetchWebinarData = async () => {
			await fetch(
				process.env.REACT_APP_SERVER_DOMAIN +
					'/api/v1/session/jump_in/' +
					wid +
					'/' +
					encodeURIComponent(session_time)
			)
				.then((res) => res.json())
				.then((data) => {
					setWebinarData(data.booking);
				})
				.catch((error) => {
					console.log(error);
					setHasError(true);
				});
		};

		fetchWebinarData();
	}, []);

	useEffect(() => {
		// set the window title
		if (webinarData && webinarData?.title) {
			document.title = `${webinarData.title} | AlexellWebinar`;

			// set the meta description
			const metaDesc = document.querySelector('meta[name="description"]');
			if (metaDesc) {
				metaDesc.setAttribute('content', webinarData.description);
			}
		}
	}, [webinarData]);

	useEffect(() => {
		// Work out if the webinar is live, finished or early, every second
		const interval = setInterval(() => {
			if (webinarData) {
				const now = new Date();
				const start_time = new Date(webinarData.start_time);
				const end_time = new Date(
					new Date(webinarData.start_time).getTime() +
						webinarData.length * 1000
				);

				if (now < start_time) {
					setStatus('early');
				} else if (now > end_time) {
					setStatus('finished');
				} else {
					setStatus('live');
				}
			}
		}, 1000);
		//Clearing the interval
		return () => clearInterval(interval);
	}, [webinarData]);

	return (
		<div className={`webinar-container ${minimized ? 'minimized' : ''}`}>
			<div className={`video-section ${minimized ? 'minimized' : ''}`}>
				{webinarData && (
					<WebinarVideo
						minimized={minimized}
						status={status}
						start_time={webinarData.start_time}
						vimeo_id={webinarData.vimeo_id}
						replay={false}
						settings={webinarData.settings}
						booking_id={webinarData.booking_id}
						webinar_id={webinarData.webinar_id}
						videoTimer={(player) => {
							setVideoPosition(parseInt(player.playedSeconds));
						}}
					/>
				)}
				{webinarData &&
					!minimized &&
					webinarData.settings.request_webcam === 'True' && (
						<Webcam />
					)}
			</div>
			<div className={`chat-section ${minimized ? 'minimized' : ''}`}>
				{webinarData && (
					<>
						{webinarData.settings.show_mobile_warning ===
							'True' && <MobileNotification />}
						{status === 'live' && <WebinarAttendeeCount />}
						<CTAWidgets
							key={`${webinarData.webinar_id}-ctas`}
							timecode={videoPosition}
							webinar_id={webinarData.webinar_id}
							replay={false}
							booking_id={webinarData.booking_id}
							booking={webinarData}
							ctas={webinarData.ctas}
							hs_list_proof={webinarData.settings.hs_list_proof}
							moderator={true}
							actionText={webinarData.settings.proof_action_text}
						/>
						<ChatBox
							key={`${webinarData.webinar_id}-chat`}
							replay={false}
							booking_id={webinarData.booking_id}
							webinar_id={webinarData.webinar_id}
							from_name={webinarData.first_name}
							session_time={webinarData.start_time}
							timecode={videoPosition}
							moderator={true}
							ctaMessages={webinarData.ctas.filter((cta) => {
								return cta.widget_type === 'chat';
							})}
						/>
					</>
				)}
			</div>
			{webinarData && (
				<CTAPageReveal
					timecode={videoPosition}
					webinar_id={webinarData.webinar_id}
					replay={false}
					booking_id={webinarData.booking_id}
					ctas={webinarData.ctas}
					hs_list_proof={webinarData.settings.hs_list_proof}
					trigger={setMinimized}
					booking={webinarData}
					moderator={true}
					actionText={webinarData.settings.proof_action_text}
				/>
			)}
		</div>
	);
};

export default JumpIn;
