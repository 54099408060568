import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import detect from 'detect.js';
import ErrorPage from './pages/ErrorPage';
import Tm_Calculator from './pages/tools/Tm_Calculator';
import Join from './pages/Join';
import JumpIn from './pages/JumpIn';
import LiveSessions from './pages/LiveSessions';
import './App.css';

function App() {
	const [hasError, setHasError] = useState(false);
	window.deviceDetected = detect.parse(navigator.userAgent);

	return (
		<div className="App">
			{hasError ? (
				<ErrorPage />
			) : (
				<Routes>
					<Route
						path="/join/:bid"
						element={
							<Join replay={false} setHasError={setHasError} />
						}
					/>
					<Route
						path="/jump_in/:wid/:session_time"
						element={<JumpIn setHasError={setHasError} />}
					/>
					<Route
						path="/replay/:bid"
						element={
							<Join replay={true} setHasError={setHasError} />
						}
					/>

					<Route
						path="/live_sessions"
						element={<LiveSessions setHasError={setHasError} />}
					/>

					<Route path="/tm/calculator" element={<Tm_Calculator />} />
				</Routes>
			)}
		</div>
	);
}

export default App;
